import { css } from '@emotion/css';
import React, { useEffect, useState } from 'react';

import { GrafanaTheme2 } from '@grafana/data';
import {
  Button,
  Card,
  Field,
  FieldSet,
  Form,
  HorizontalGroup,
  Input,
  useStyles2,
  IconButton,
  Icon,
  TextArea,
  InlineSwitch,
} from '@grafana/ui';

import { NotfType, NotfThresholdType, NotfTriggerType, NotfSeverityType } from './enums';
import {
  NotfConnObj,
  NotfEmailConnDetails,
  NotfSettingMetadata,
  NotfTelegramConnDetails,
  NotfWhatsappConnDetails,
} from './types';

export interface Props {
  tagName: string;
  tagSettingMetadata: NotfSettingMetadata[];
  onRemoveThresholdClick: (selectedSettingMetadata: NotfSettingMetadata) => void;
  onEditThresholdClick: (settingMetadata: NotfSettingMetadata) => void;
  onAddThresholdClick: () => void;
  onAddThresholdConfirmClick?: (tagName: string, threshold: NotfSettingMetadata) => void;
  onThresholdNameSelected: (s: string) => void;
  onTestTriggerClick: (metadata: NotfConnObj) => () => void;
  onDisableSettingClick: (tagName: string, thresholdName: string, on: any) => void;
  onCopySettingClick: (settingMetadata: NotfSettingMetadata) => void;
}
export const listOfNotificationType: Array<{ label: string; value: string }> = [
  { value: NotfType.unspecified, label: 'Unspecified' },
  { value: NotfType.telegram, label: 'Telegram' },
  { value: NotfType.email, label: 'Email' },
  { value: NotfType.whatsapp, label: 'WhatsApp' },
];

export const listOfThresholdType: Array<{ label: string; value: string }> = [
  { value: NotfThresholdType.unspecified, label: 'Unspecified' },
  { value: NotfThresholdType.greater_than, label: '>' },
  { value: NotfThresholdType.greater_equal_than, label: '≥' },
  { value: NotfThresholdType.lower_than, label: '<' },
  { value: NotfThresholdType.lower_equal_than, label: '≤' },
];

export const listOfTriggerType: Array<{ label: string; value: string }> = [
  { value: NotfTriggerType.unspecified, label: 'Unspecified' },
  { value: NotfTriggerType.on_received, label: 'On Received' },
  { value: NotfTriggerType.on_changed, label: 'On Changed' },
];

export const getLabel = (list: Array<{ label: string; value: string | number }>, value?: string): string => {
  return list.filter((l) => l.value === value)[0]?.label || '';
};

export const listOfSeverityType: Array<{ label: string; value: string }> = [
  { value: NotfSeverityType.Low, label: 'Low' },
  { value: NotfSeverityType.High, label: 'High' },
];

export const Settings = (props: Props) => {
  const {
    tagName,
    tagSettingMetadata,
    onRemoveThresholdClick,
    onEditThresholdClick,
    onAddThresholdClick,
    onThresholdNameSelected,
    onTestTriggerClick,
    onDisableSettingClick,
    onCopySettingClick,
  } = props;

  const s = useStyles2(getStyles);

  // selected metadata ---------------------------------------------------------
  const [selectedSettingMetadata, setSelectedSettingMetadata] = useState<NotfSettingMetadata | null>();

  // tag name changed ----------------------------------------------------------
  useEffect(() => {
    /* @ts-ignore-next-line */
    setSelectedSettingMetadata(null);
  }, [tagName]);

  useEffect(() => {
    if (tagSettingMetadata && selectedSettingMetadata) {
      const selected = tagSettingMetadata.filter((t) => t.thresholdName === selectedSettingMetadata.thresholdName);
      if (selected.length > 0) {
        setSelectedSettingMetadata(selected[0]);
      }
    }
  }, [tagSettingMetadata, selectedSettingMetadata]);

  // render settings -----------------------------------------------------------
  const renderSettingList = () => {
    const list = tagSettingMetadata?.map((setting) => {
      return (
        <li key={setting.thresholdName} className={s.li}>
          <Card
            onClick={() => {
              onThresholdNameSelected(setting.thresholdName);
              setSelectedSettingMetadata(setting);
            }}
            className={
              selectedSettingMetadata && selectedSettingMetadata?.thresholdName === setting.thresholdName
                ? s.selected
                : ''
            }
          >
            <Card.Heading>Name: {setting.thresholdName}</Card.Heading>
            <Card.Meta>
              {[
                `${getLabel(listOfThresholdType, setting?.thresholdType)} ${setting.thresholdValue}`,
                <div key="list">
                  <Icon name="bell" style={{ marginRight: '0.5em' }}></Icon>
                  <span>
                    {(() => {
                      if (setting.metadata.length === 0) {
                        return <span className={s.notConfigured}>Not cofigured</span>;
                      }
                      return setting.metadata
                        .map((n) => getLabel(listOfNotificationType, n.notificationType))
                        .join(', ');
                    })()}
                  </span>
                </div>,
              ]}
            </Card.Meta>
            <Card.SecondaryActions>
              {/* @ts-ignore-next-line */}
              <IconButton
                key="copy"
                name="copy"
                title="Copy setting"
                onClick={() => onCopySettingClick(setting)}
                disabled={disableBtn}
              ></IconButton>
              {/* @ts-ignore-next-line */}
              <IconButton
                key="edit"
                name="pen"
                title="Edit setting"
                onClick={() => onEditThresholdClick(setting)}
                disabled={disableBtn}
              ></IconButton>
              {/* @ts-ignore-next-line */}
              <IconButton
                key="delete"
                name="trash-alt"
                title="Delete setting"
                variant="destructive"
                onClick={() => {
                  onRemoveThresholdClick(setting);
                }}
                disabled={disableBtn}
              ></IconButton>
              <InlineSwitch
                className={s.disableSettingSwitch}
                title="Disable setting"
                showLabel={false}
                transparent={true}
                value={setting.disabled === 'true' ? false : true}
                onChange={(e: any) => onDisableSettingClick(setting.tagName, setting.thresholdName, e.target.checked)}
                disabled={disableBtn}
              />
            </Card.SecondaryActions>
          </Card>
        </li>
      );
    });
    if (list && list.length > 0) {
      return <ul className={s.list}>{list}</ul>;
    }
    return <p className={s.notFound}>No setting found.</p>;
  };

  // disable button -------------------------------------------------------------
  const [disableBtn, setDisableBtn] = useState(true);
  useEffect(() => {
    if (!selectedSettingMetadata || !tagName) {
      setDisableBtn(true);
      return;
    }
    setDisableBtn(false);
    // eslint-disable-next-line
  }, [selectedSettingMetadata]);

  // message parser -------------------------------------------------------------
  // useEffect(() => {
  // const messageParser = new MessageParser();
  // }, []);

  // connection details ---------------------------------------------------------
  const renderConnectionDetails = () => {
    const renders = [];
    if (selectedSettingMetadata && Array.isArray(selectedSettingMetadata.metadata)) {
      for (const n of selectedSettingMetadata.metadata) {
        switch (n.notificationType) {
          case NotfType.telegram:
            renders.push(
              <FieldSet label="" key={n.metadataId} className={s.notificationSetting}>
                <div className={s.testTrigger}>
                  {/* eslint-disable-next-line */}
                  <IconButton name="bug" title="Test trigger" onClick={onTestTriggerClick(n)} aria-label="" />
                </div>
                <HorizontalGroup>
                  <Field label="Trigger Type">
                    <Input defaultValue={getLabel(listOfTriggerType, n.triggerType)} disabled={true} />
                  </Field>
                  <Field label="Notification Interval (seconds)">
                    <Input defaultValue={n.betweenTriggerInSecond || 0} disabled={true} />
                  </Field>
                  <Field label="Notification Type">
                    <Input defaultValue={getLabel(listOfNotificationType, n.notificationType)} disabled={true} />
                  </Field>
                </HorizontalGroup>
                <Field label="Message">
                  <TextArea defaultValue={n.message || ''} disabled={true} style={{ width: '100%' }} />
                </Field>
                <Field label="Channel Id">
                  <Input defaultValue={(n.connDetails as NotfTelegramConnDetails).channelIds || ''} disabled={true} />
                </Field>
              </FieldSet>
            );
            break;
          case NotfType.email:
            renders.push(
              <FieldSet label="" key={n.metadataId} className={s.notificationSetting}>
                <div className={s.testTrigger}>
                  {/* @ts-ignore-next-line */}
                  <IconButton name="bug" title="Test trigger" onClick={onTestTriggerClick(n)} />
                </div>
                <HorizontalGroup>
                  <Field label="Trigger Type">
                    <Input defaultValue={getLabel(listOfTriggerType, n.triggerType)} disabled={true} />
                  </Field>
                  <Field label="Notification Interval (seconds)">
                    <Input defaultValue={n.betweenTriggerInSecond || 0} disabled={true} />
                  </Field>
                  <Field label="Notification Type">
                    <Input defaultValue={getLabel(listOfNotificationType, n.notificationType)} disabled={true} />
                  </Field>
                </HorizontalGroup>
                <HorizontalGroup>
                  <Field label="Subject">
                    <Input defaultValue={(n.connDetails as NotfEmailConnDetails).subject} disabled={true} />
                  </Field>
                  <Field label={`To`}>
                    <Input defaultValue={(n.connDetails as NotfEmailConnDetails).to || ''} disabled={true} />
                  </Field>
                  <Field label={`CC`}>
                    <Input defaultValue={(n.connDetails as NotfEmailConnDetails).cc} disabled={true} />
                  </Field>
                </HorizontalGroup>
                <TextArea defaultValue={n.message || ''} disabled={true} />
              </FieldSet>
            );
            break;
          case NotfType.whatsapp:
            renders.push(
              <FieldSet label="" key={n.metadataId} className={s.notificationSetting}>
                <div className={s.testTrigger}>
                  {/* @ts-ignore-next-line */}
                  <IconButton name="bug" title="Test trigger" onClick={onTestTriggerClick(n)} />
                </div>
                <HorizontalGroup>
                  <Field label="Trigger Type">
                    <Input defaultValue={getLabel(listOfTriggerType, n.triggerType)} disabled={true} />
                  </Field>
                  <Field label="Notification Interval (seconds)">
                    <Input defaultValue={n.betweenTriggerInSecond || 0} disabled={true} />
                  </Field>
                  <Field label="Notification Type">
                    <Input defaultValue={getLabel(listOfNotificationType, n.notificationType)} disabled={true} />
                  </Field>
                </HorizontalGroup>
                <HorizontalGroup>
                  <Field label="Recipient">
                    <Input defaultValue={(n.connDetails as NotfWhatsappConnDetails).recipient || ''} disabled={true} />
                  </Field>
                </HorizontalGroup>
                <TextArea defaultValue={n.message || ''} disabled={true} />
              </FieldSet>
            );
            break;
        }
      }
    }
    return renders;
  };

  const renderSettingForm = () => {
    return (
      <FieldSet label="" className={s.formFieldSetStyle}>
        <HorizontalGroup>
          <Field label="Threshold Name">
            <Input value={selectedSettingMetadata?.thresholdName || ''} disabled={true} />
          </Field>
          <Field label="Threshold Rule">
            <Input value={getLabel(listOfThresholdType, selectedSettingMetadata?.thresholdType)} disabled={true} />
          </Field>
          <Field label="Threshold Value">
            <Input value={selectedSettingMetadata?.thresholdValue || 0} disabled={true} />
          </Field>
          <Field label="Severity">
            <Input value={getLabel(listOfSeverityType, selectedSettingMetadata?.severity)} disabled={true} />
          </Field>
        </HorizontalGroup>
        <Field label="Target Page">
          <Input value={selectedSettingMetadata?.targetURL || ''} disabled={true} />
        </Field>
        {renderConnectionDetails()}
      </FieldSet>
    );
  };

  // main render ---------------------------------------------------------------------
  return (
    <section className={s.sectionStyle}>
      <Form onSubmit={() => { }} className={s.formStyle}>
        {() => (
          <>
            <div className={s.thresholdSettings}>
              <div className={s.settingTitle}>
                {/*eslint-disable-next-line react/no-children-prop*/}
                <FieldSet label="Setting" className={s.thresholdTitle} children={null} />
                <Button onClick={onAddThresholdClick} disabled={!tagName} style={{ transform: 'translateY(-10px)' }}>
                  New
                </Button>
              </div>
              <ul>{renderSettingList()}</ul>
            </div>
            <div className={s.settingForm}>{selectedSettingMetadata && renderSettingForm()}</div>
          </>
        )}
      </Form>
    </section>
  );
};

const getStyles = (theme: GrafanaTheme2) => ({
  sectionStyle: css`
    width: 100%;
    height: calc(100vh - 25rem);
    overflow: auto;
    padding: 10px;
  `,
  formStyle: css`
    max-width: 100%;
  `,
  formFieldSetStyle: css`
    padding: 20px 10px;
  `,
  thresholdSettings: css`
    overflow: auto;
    padding: 0 10px;
  `,
  settingTitle: css`
    height: auto;
    display: flex;
    align-items: center;
    gap: 10px;
  `,
  thresholdTitle: css`
    margin-bottom: 0;
  `,
  list: css`
    list-style: none;
    display: grid;
  `,
  li: css`
    line-height: 1.5;
  `,
  notFound: css`
    font-size: ${theme.typography.h6.fontSize};
    text-align: center;
    heght: 290px;
  `,
  notificationSetting: css`
    position: relative;
    border: 1px solid #cdcdcd;
    padding: 1.5em;
    margin-bottom: 10px !important;
  `,
  selected: css`
    border-left: 2px solid ${theme.colors.primary.text};
  `,
  notConfigured: css`
    color: ${theme.colors.warning.text};
  `,
  testTrigger: css`
    position: absolute;
    right: 8px;
    top: 8px;
  `,
  disableSettingSwitch: css`
    padding: 0;
  `,
  settingForm: css`
    background-color: ${theme.colors.background.secondary}55;
  `,
});
