import { css, cx } from '@emotion/css';
import classNames from 'classnames';
import React, { PropsWithChildren, useEffect, useState } from 'react';

import { GrafanaTheme2, PageLayoutType } from '@grafana/data';
import { locationService } from '@grafana/runtime';
import { useStyles2, LinkButton, useTheme2 } from '@grafana/ui';
import config from 'app/core/config';
import { useGrafana } from 'app/core/context/GrafanaContext';
import { useMediaQueryChange } from 'app/core/hooks/useMediaQueryChange';
import { CommandPalette } from 'app/features/commandPalette/CommandPalette';
import { KioskMode } from 'app/types';

import { AppChromeMenu } from './AppChromeMenu';
import { MegaMenu as DockedMegaMenu, MENU_WIDTH } from './DockedMegaMenu/MegaMenu';
import { MegaMenu as DockedMegaMenuPersistentIcon } from './DockedMegaMenu/MegaMenuPersistentIcon';
import { MegaMenu } from './MegaMenu/MegaMenu';
import { MegaMenu as MegaMenuPersistentIcon } from './MegaMenu/MegaMenuPersistentIcon';
import { NavToolbar } from './NavToolbar/NavToolbar';
import { SectionNav } from './SectionNav/SectionNav';
import { TopSearchBar } from './TopBar/TopSearchBar';
import { TOP_BAR_LEVEL_HEIGHT } from './types';
import { useDashboardStructure } from './useDashboardStructure';

export interface Props extends PropsWithChildren<{}> { }

export function AppChrome({ children }: Props) {
  const { chrome } = useGrafana();
  const state = chrome.useState();
  const searchBarHidden = state.searchBarHidden || state.kioskMode === KioskMode.TV;
  const theme = useTheme2();
  const styles = useStyles2(getStyles);
  const [, updateDashboardStructure] = useDashboardStructure();
  const breakpoint = theme.breakpoints.values.md;
  const [isSmallScreen, setIsSmallScreen] = useState(!window.matchMedia(`(min-width: ${breakpoint}px)`).matches);

  useMediaQueryChange({
    breakpoint,
    onChange: (e: MediaQueryListEvent) => {
      setIsSmallScreen(!e.matches);
    },
  });

  useEffect(() => {
    const location = locationService.getLocation();
    if (location.pathname === '/login') {
      return;
    }
    updateDashboardStructure();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const contentClass = cx({
    [styles.content]: true,
    [styles.contentChromeless]: state.chromeless,
  });

  const dockedMegaMenuClass = cx({
    [styles.dockedMegaMenu]: true,
    [styles.dockedMegaMenuNoSearchBar]: searchBarHidden,
  });

  const handleMegaMenu = () => {
    switch (state.megaMenu) {
      case 'closed':
        chrome.setMegaMenu('open');
        break;
      case 'open':
        if (isSmallScreen) {
          chrome.setMegaMenu('closed');
          break;
        }
        chrome.setMegaMenu('icon-persistent-closed');
        break;
      case 'docked':
        // on desktop, clicking the button when the menu is docked should close the menu
        // on mobile, the docked menu is hidden, so clicking the button should open the menu
        const isDesktop = window.innerWidth > theme.breakpoints.values.md;
        isDesktop ? chrome.setMegaMenu('closed') : chrome.setMegaMenu('open');
        break;
      case 'icon-persistent-closed':
        if (!isSmallScreen) {
          chrome.setMegaMenu('docked');
          break;
        }
        chrome.setMegaMenu('open');
        break;
    }
  };

  const renderChromeMenu = () => {
    // with docked feature
    if (config.featureToggles.dockedMegaMenu) {
      // docked or show persistent icon
      if ((state.megaMenu === 'docked' || state.megaMenu === 'icon-persistent-closed') && !isSmallScreen) {
        return (
          <>
            <AppChromeMenu />
            <CommandPalette />
          </>
        );
      }
      // after the megaMenu is open
      if (state.megaMenu === 'open' && !isSmallScreen) {
        return (
          <>
            <MegaMenuPersistentIcon
              searchBarHidden={searchBarHidden}
              onClose={() => chrome.setMegaMenu('icon-persistent-closed')}
            />
            <CommandPalette />
          </>
        );
      }
    }

    return (
      <>
        <MegaMenu searchBarHidden={searchBarHidden} onClose={() => chrome.setMegaMenu('closed')} />
        <CommandPalette />
      </>
    );
  };

  // Chromeless routes are without topNav, mega menu, search & command palette
  // We check chromeless twice here instead of having a separate path so {children}
  // doesn't get re-mounted when chromeless goes from true to false.
  return (
    <div
      className={classNames('main-view', {
        'main-view--search-bar-hidden': searchBarHidden && !state.chromeless,
        'main-view--chrome-hidden': state.chromeless,
      })}
    >
      {!state.chromeless && (
        <>
          <LinkButton className={styles.skipLink} href="#pageContent">
            Skip to main content
          </LinkButton>
          <div className={cx(styles.topNav)}>
            {!searchBarHidden && <TopSearchBar />}
            <NavToolbar
              searchBarHidden={searchBarHidden}
              sectionNav={state.sectionNav.node}
              pageNav={state.pageNav}
              actions={state.actions}
              onToggleSearchBar={chrome.onToggleSearchBar}
              onToggleMegaMenu={handleMegaMenu}
              onToggleKioskMode={chrome.onToggleKioskMode}
            />
          </div>
        </>
      )}
      <main className={contentClass}>
        <div className={styles.panes}>
          <div id="sidenav">
            {state.layout === PageLayoutType.Standard && state.sectionNav && !config.featureToggles.dockedMegaMenu && (
              <SectionNav model={state.sectionNav} />
            )}
            {config.featureToggles.dockedMegaMenu && !state.chromeless && state.megaMenu === 'docked' && (
              <>
                <DockedMegaMenu className={dockedMegaMenuClass} onClose={() => chrome.setMegaMenu('closed')} />
                <img className={styles.godigitalLogo} src="public/img/go_digital_logo.png" alt="GoDigital Logo" />
              </>
            )}
            {/* with persistent icon, add in the `open` state so that when the megaMenu is open,
            the docked megaMenu will stay behind the overlay, occupying the space,
            thus won't cause the main content to jitter */}
            {config.featureToggles.dockedMegaMenu &&
              !state.chromeless &&
              (state.megaMenu === 'icon-persistent-closed' || state.megaMenu === 'open') && (
                <DockedMegaMenuPersistentIcon
                  className={dockedMegaMenuClass}
                  onClose={() => chrome.setMegaMenu('icon-persistent-closed')}
                />
              )}
          </div>
          <div className={styles.pageContainer} id="pageContent">
            {children}
          </div>
        </div>
      </main>
      {!state.chromeless && renderChromeMenu()}
    </div>
  );
}

const getStyles = (theme: GrafanaTheme2) => {
  const shadow = theme.isDark
    ? `0 0.6px 1.5px rgb(0 0 0), 0 2px 4px rgb(0 0 0 / 40%), 0 5px 10px rgb(0 0 0 / 23%)`
    : '0 4px 8px rgb(0 0 0 / 4%)';

  return {
    content: css({
      display: 'flex',
      flexDirection: 'column',
      paddingBottom: '15px', // 15px is the footer height
      flexGrow: 1,
      height: '100%',
      overflow: 'hidden',
    }),
    contentChromeless: css({
      paddingTop: 0,
    }),
    dockedMegaMenu: css({
      background: theme.colors.background.sideMenu,
      display: 'none',
      zIndex: theme.zIndex.navbarFixed,
      marginTop: TOP_BAR_LEVEL_HEIGHT * 2,
      height: '100%',

      [theme.breakpoints.up('md')]: {
        display: 'block',
      },
    }),
    dockedMegaMenuNoSearchBar: css({
      marginTop: TOP_BAR_LEVEL_HEIGHT,
    }),
    topNav: css({
      display: 'flex',
      position: 'fixed',
      zIndex: theme.zIndex.navbarFixed,
      left: 0,
      right: 0,
      boxShadow: shadow,
      background: 'transparent',
      flexDirection: 'column',
      backdropFilter: 'blur(20px) brightness(0.9) saturate(180%)',
    }),
    panes: css({
      label: 'page-panes',
      display: 'flex',
      height: '100%',
      width: '100%',
      flexGrow: 1,
      minHeight: 0,
      flexDirection: 'column',
      [theme.breakpoints.up('md')]: {
        flexDirection: 'row',
      },
    }),
    pageContainer: css({
      label: 'page-container',
      flexGrow: 1,
      minHeight: 0,
      minWidth: 0,
      overflow: 'auto',
    }),
    skipLink: css({
      position: 'absolute',
      top: -1000,

      ':focus': {
        left: theme.spacing(1),
        top: theme.spacing(1),
        zIndex: theme.zIndex.portal,
      },
    }),
    godigitalLogo: css({
      position: 'absolute',
      left: 0,
      bottom: '15px',
      width: MENU_WIDTH,
      height: '60px',
      backgroundColor: 'transparent',
      objectFit: 'contain',
      zIndex: theme.zIndex.navbarFixed,
      backdropFilter: 'blur(20px)',
    }),
  };
};
