import { css } from '@emotion/css';
import React from 'react';

import { GrafanaTheme2, NavModelItem } from '@grafana/data';
import { darken } from '@grafana/data/src/themes/colorManipulator';
import { useStyles2 } from '@grafana/ui';

import { NavBarItemIcon } from './NavBarItemIcon';
import { NavBarMenuItem } from './NavBarMenuItem';
import { NavBarMenuSection } from './NavBarMenuSection';
import { isMatchOrChildMatch } from './utils';

export function NavBarMenuItemWrapper({
  link,
  activeItem,
  onClose,
  isChild,
  level = 0,
}: {
  link: NavModelItem;
  activeItem?: NavModelItem;
  onClose: () => void;
  isChild?: boolean;
  level?: number;
}) {
  const styles = useStyles2(getStyles, level);

  if (link.emptyMessage && !linkHasChildren(link)) {
    return (
      <NavBarMenuSection onClose={onClose} link={link} activeItem={activeItem}>
        <ul className={styles.children}>
          <div className={styles.emptyMessage}>{link.emptyMessage}</div>
        </ul>
      </NavBarMenuSection>
    );
  }

  const renderNested = (link: NavModelItem) => {
    return (
      <NavBarMenuItemWrapper
        key={`${Math.random()}-${link.text}`}
        link={link}
        onClose={onClose}
        activeItem={activeItem}
        isChild={true}
        level={level + 1}
      />
    );
  };

  const renderIconElement = (link: NavModelItem) => {
    if (link.icon || link.img) {
      return <NavBarItemIcon link={link} />
    }

    link.icon = "graph-bar";
    return <NavBarItemIcon link={link} />;
  };

  return (
    <NavBarMenuSection onClose={onClose} link={link} activeItem={activeItem} isChild={isChild} level={level}>
      {linkHasChildren(link) && (
        <ul className={styles.children}>
          {link.children.map((childLink) => {
            if (linkHasChildren(childLink)) {
              return renderNested(childLink);
            }
            return (
              !childLink.isCreateAction && (
                <NavBarMenuItem
                  key={`${link.text}-${childLink.text}`}
                  isActive={isMatchOrChildMatch(childLink, activeItem)}
                  isChild
                  onClick={() => {
                    childLink.onClick?.();
                    onClose();
                  }}
                  target={childLink.target}
                  url={childLink.url}
                >
                  <div className={styles.labelWrapper}>
                    {renderIconElement(childLink)}
                    {childLink.text}
                  </div>
                </NavBarMenuItem>
              )
            );
          })}
        </ul>
      )}
    </NavBarMenuSection>
  );
}

const getStyles = (theme: GrafanaTheme2, level = 0) => ({
  children: css({
    display: 'flex',
    flexDirection: 'column',
    backgroundColor: darken(theme.colors.background.navBar, ++level / 10),
  }),
  flex: css({
    display: 'flex',
  }),
  itemWithoutMenu: css({
    position: 'relative',
    placeItems: 'inherit',
    justifyContent: 'start',
    display: 'flex',
    flexGrow: 1,
    alignItems: 'center',
  }),
  fullWidth: css({
    height: '100%',
    width: '100%',
  }),
  iconContainer: css({
    display: 'flex',
    placeContent: 'center',
  }),
  itemWithoutMenuContent: css({
    display: 'grid',
    gridAutoFlow: 'column',
    gridTemplateColumns: `${theme.spacing(7)} auto`,
    alignItems: 'center',
    height: '100%',
  }),
  linkText: css({
    fontSize: theme.typography.pxToRem(14),
    justifySelf: 'start',
  }),
  emptyMessage: css({
    color: theme.colors.text.navBar,
    fontStyle: 'italic',
    padding: theme.spacing(1, 1.5, 1, 7),
  }),
  labelWrapper: css({
    display: 'grid',
    gridAutoFlow: 'column',
    gap: theme.spacing(2),
    paddingLeft: theme.spacing(2),
    placeItems: 'center',
  }),
});

function linkHasChildren(link: NavModelItem): link is NavModelItem & { children: NavModelItem[] } {
  return Boolean(link.children && link.children.length > 0);
}
