import { css } from '@emotion/css';
import { keyframes } from '@emotion/react';
import React, { useState, useEffect, useRef } from 'react';

import { GrafanaTheme2 } from '@grafana/data';

import { useStyles2 } from '../../themes';
import { ToolbarButton, ToolbarButtonProps } from '../ToolbarButton/ToolbarButton';

export interface Props extends ToolbarButtonProps {
  counter?: string;
  dot?: boolean;
  dotColor?: string;
  flashing?: boolean;
  stopFlashingAfterInSecond?: number;
}

export const ToolbarButtonWithCounter = (props: Props) => {
  const { counter, dot, dotColor, flashing, stopFlashingAfterInSecond, ...rest } = props;

  const style = useStyles2(getStyles(dotColor, flashing, dot));

  const previousCounter = useRef<string | undefined>();
  useEffect(() => {
    previousCounter.current = counter;
  });

  const getCounter = () => {
    if (counter) {
      if (parseInt(counter, 10) === 0) {
        return '';
      } else if (parseInt(counter, 10) > 99) {
        return '99+';
      }
    }
    return counter;
  };

  const [shouldShowIndicator, setShouldShowIndicator] = useState(false);
  useEffect(() => {
    if (counter && parseInt(counter, 10) > 0) {
      setShouldShowIndicator(true);
      return;
    }
    setShouldShowIndicator(false);
  }, [counter]);

  const [text, setText] = useState<string | undefined>();
  const [counterStyle, setCounterStyle] = useState(style.counter);
  useEffect(() => {
    if (shouldShowIndicator) {
      if (dot) {
        setText('');
      } else {
        setText(getCounter());
      }

      if (flashing) {
        setCounterStyle(style.counterFlashing);
        if (stopFlashingAfterInSecond && stopFlashingAfterInSecond !== 0) {
          setTimeout(() => {
            setCounterStyle(style.counter);
          }, stopFlashingAfterInSecond * 1000);
        }
      } else {
        setCounterStyle(style.counter);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [shouldShowIndicator, dot, flashing, counter, stopFlashingAfterInSecond]);

  return (
    <ToolbarButton {...rest}>
      <div className={(counter !== previousCounter.current || counter) ? counterStyle : ''}>
        <span>{text}</span>
      </div>
    </ToolbarButton>
  );
};

const getStyles = (...styleProps: any) => (theme: GrafanaTheme2) => {
  const [badgeColor, flashing, dot] = styleProps;

  const backgroundColor = (): any => {
    if (dot) {
      if (badgeColor) {
        return badgeColor;
      }
      return theme.colors.error.main;
    }
    return 'none';
  }

  return {
    //eslint-disable-next-line @emotion/syntax-preference
    counter: css`
      position: absolute;
      display: flex;
      width: 10px;
      height: 10px;
      align-items: center;
      justify-content: center;
      top: 5px;
      right: 5px;
      font-weight: bold;
      color: ${badgeColor ?? theme.colors.error.main};
      font-size: 0.8em;
      border-radius: 10px;
      border: 1px solid ${backgroundColor()};
      background-color: ${backgroundColor()};
    `,
    //eslint-disable-next-line @emotion/syntax-preference
    counterFlashing: css`
      position: absolute;
      display: flex;
      width: 10px;
      height: 10px;
      align-items: center;
      justify-content: center;
      top: 5px;
      right: 5px;
      font-weight: bold;
      color: ${badgeColor ?? theme.colors.error.main};
      font-size: 0.8em;
      border-radius: 10px;
      border: 1px solid ${backgroundColor()};
      background-color: ${backgroundColor()};
      animation: ${flashing ? flash : null} 0.5s linear infinite;
    `,
  };
};

const flash = keyframes`
  50% {
    opacity: 0;
  }
`;
