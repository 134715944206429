import { css, cx } from '@emotion/css';
import moment from 'moment';
import React, { useState, useRef, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { useEffectOnce } from 'react-use';
import { Subscription } from 'rxjs';
import { RetryConfig, retry } from 'rxjs/operators';
import { webSocket } from 'rxjs/webSocket';

import { GrafanaTheme2 } from '@grafana/data';
import { config, getBackendSrv } from '@grafana/runtime';
import { Drawer, useStyles2, IconButton, Card, ConfirmModal } from '@grafana/ui';
import { contextSrv } from 'app/core/services/context_srv';
import { convertToWebSocketUrl } from 'app/core/utils/explore';
import { getLabel, listOfThresholdType } from 'app/features/notification-setting/Settings';
import { NotfSeverityType } from 'app/features/notification-setting/enums';

import { AcknowledgementDto, NotfAlertLogDto } from './types';


export interface Props {
  show: boolean;
  onClose: () => void;
  updateCounter: (counter: number) => void;
}

// TODO: chewcw - to refactor to be a global component
export const NotificationList = (props: Props) => {
  const { show, onClose, updateCounter } = props;
  const history = useHistory();

  const s = useStyles2(getStyles);

  const [login, setLogin] = useState('');
  useEffect(() => {
    getBackendSrv()
      .get('/api/user')
      .then((user: any) => {
        if (user?.login) {
          setLogin(user.login);
        }
      });
  });

  const [confirmation, setConfirmation] = useState({
    show: false,
    title: '',
    message: '',
    confirmMessage: '',
    ackFunction: () => { },
  });

  const onAckClick = (thresholdName: string, tagName: string, triggerLogID: number) => () => {
    const acknowledgements: AcknowledgementDto[] = [];
    acknowledgements.push({
      thresholdName,
      tagName,
      acknowledgedBy: login,
      triggerLogID,
    });

    getBackendSrv()
      .post(`/api-notification/notification/acknowledge`, acknowledgements)
      .then(() => {
        getAlertLogs().catch((error) => {
          console.error(error);
        });
      })
      .catch((error) => {
        console.error(error);
      });
  };

  const onAckAllClick = (alertLogs: NotfAlertLogDto[]) => () => {
    const acknowledgements: AcknowledgementDto[] = alertLogs.map((alertLog) => ({
      thresholdName: alertLog.thresholdName,
      tagName: alertLog.tagName,
      acknowledgedBy: login,
      triggerLogID: alertLog.triggerLogID,
    }));
    getBackendSrv()
      .post(`/api-notification/notification/acknowledge`, acknowledgements)
      .then(() => {
        getAlertLogs().catch((error) => {
          console.error(error);
        });
      })
      .catch((error) => {
        console.error(error);
        return;
      });
  };

  const [alertLogs, setAlertLogs] = useState<NotfAlertLogDto[]>([]);
  const alertLogsRef = useRef<NotfAlertLogDto[]>([]);
  useEffectOnce(() => {
    let subscriber: Subscription;

    if (!config.ynyNotificationEnabled) {
      return;
    }

    // getAlertLogs only run once when the component mount
    // later handover to subscriber for subsequent updates
    getAlertLogs()
      .then(() => {
        // then subscribe to updates
        const currentOrgId = contextSrv.user.orgId;
        const url = convertToWebSocketUrl(`/api-notification/notification/ws?organizationid=${currentOrgId}`);
        console.log('registered notification log websocket connection');
        const retryConfig: RetryConfig = { delay: 5000 };
        subscriber = webSocket<NotfAlertLogDto>(url)
          .pipe(retry(retryConfig))
          .subscribe((alertLog) => {
            const newAlertLogs: NotfAlertLogDto[] = [...alertLogsRef.current];
            // if same triggerLogID, combine it into the existing list
            const found = newAlertLogs.findIndex((n) => n.triggerLogID === alertLog.triggerLogID);
            if (found !== -1) {
              newAlertLogs.splice(found, 1);
              newAlertLogs.unshift(alertLog);
            } else {
              newAlertLogs.push(alertLog);
            }
            setAlertLogs(newAlertLogs);
            updateCounter(newAlertLogs.length);
          });
      })
      .catch((error) => {
        console.error(error);
        return;
      });

    return () => {
      if (subscriber) {
        subscriber.unsubscribe();
        console.log('unregistered notification log websocket connection');
      }
    };
  });

  const getAlertLogs = async (): Promise<void> => {
    return new Promise((resolve, reject) => {
      const currentOrgId = contextSrv.user.orgId;
      getBackendSrv()
        .get(`/api-notification/store/alert-log?organizationid=${currentOrgId}`)
        .then((responses: NotfAlertLogDto[]) => {
          setAlertLogs(responses);
          updateCounter(responses.length);
          resolve();
        })
        .catch((error: any) => {
          console.error(error);
          reject(error);
        });
    });
  };

  useEffect(() => {
    alertLogsRef.current = alertLogs;
  }, [alertLogs]);

  const renderAlertList = (alertLogs: NotfAlertLogDto[]) => {
    return alertLogs.map((alertLog) => (
      <li className={s.listItem} key={alertLog.alertAt}>
        <Card className={cx(s.card, alertLog.overtaken ? s.cardOvertaken : null)}>
          <Card.Figure>
            <img
              src={
                alertLog.severity === NotfSeverityType.High
                  ? `public/img/exclamation-mark-red.svg`
                  : `public/img/exclamation-mark-yellow.svg`
              }
              alt=""
              height="40px"
              width="40px"
            />
          </Card.Figure>
          <Card.Heading className={s.tagName}>{alertLog?.tagName}</Card.Heading>
          <Card.Description className={s.description}>
            <span>Value</span>
            &nbsp;
            <span style={{ fontWeight: 'bold' }}>{alertLog?.actualValue}</span>
            &nbsp;
            <span>{getLabel(listOfThresholdType, alertLog?.thresholdType)}</span>
            &nbsp;
            <span>Setpoint</span>
            &nbsp;
            <span style={{ fontWeight: 'bold' }}>{alertLog?.thresholdValue}</span>
          </Card.Description>
          <Card.Meta className={s.listItemChild}>
            <small>
              <span>{alertLog?.thresholdName}</span>
            </small>
            <small>
              <span>{formatTime(getMillisecondsFromNow(alertLog?.alertAt), true)}</span>
              <span>&nbsp;ago</span>
            </small>
          </Card.Meta>
          <Card.Actions className={s.actionButtons}>
            {/* @ts-ignore-next-line */}
            <IconButton
              name="check"
              size="md"
              className={s.actionButton}
              title="Acknowledge"
              onClick={() =>
                setConfirmation({
                  show: true,
                  title: 'Acknowledgement',
                  message:
                    'Are you sure you want to acknowledge this notification and clear this log from the notification list?',
                  confirmMessage: 'Yes, acknowledge',
                  ackFunction: onAckClick(alertLog.thresholdName, alertLog.tagName, alertLog.triggerLogID),
                })
              }
            >
              Acknowledge
            </IconButton>
            {alertLog.targetURL !== '' ? (
              // @ts-ignore-next-line
              <IconButton
                name="arrow-right"
                size="md"
                className={s.actionButton}
                title="Go to target page"
                onClick={() => history.push(alertLog.targetURL)}
              >
                Goto
              </IconButton>
            ) : null}
          </Card.Actions>
        </Card>
      </li>
    ));
  };

  const getMillisecondsFromNow = (alertAt?: string) => {
    if (alertAt) {
      const alertLocaleDateTime = new Date(alertAt).toLocaleString();
      return Date.now() - new Date(alertLocaleDateTime).valueOf();
    }
    return 0;
  };

  const formatTime = (timeInMs: number, humanize = false): string => {
    const inSeconds = timeInMs / 1000;

    if (!humanize) {
      return `${inSeconds.toFixed(1)}s`;
    }

    const duration = moment.duration(inSeconds, 'seconds');
    const days = duration.days();
    const hours = duration.hours();
    const minutes = duration.minutes();
    const seconds = duration.seconds();

    if (days) {
      return `${days}d ${hours}h ${minutes}m ${seconds}s`;
    }

    if (hours) {
      return `${hours}h ${minutes}m ${seconds}s`;
    }

    if (minutes) {
      return `${minutes}m ${seconds}s`;
    }

    return `${seconds}s`;
  };

  return (
    <>
      {show && (
        <Drawer
          title="Notification"
          width="30%"
          onClose={onClose}
          scrollableContent={true}
          drawerStylesOverride={s.drawerStylesOverride}
        >
          <section className={s.section}>
            <div className={s.subtitleSection}>
              <Card className={s.cardUnread}>
                <div className={s.unreadIndicator}>
                  {alertLogs.length}&nbsp;
                  <span>{alertLogs.length > 1 ? 'messages' : 'message'} unread</span>
                </div>
                {/* @ts-ignore-next-line */}
                <IconButton
                  name="bell-slash"
                  size="md"
                  className={cx(s.actionButton, s.ackAllButton)}
                  title="Acknowledge all"
                  disabled={alertLogs && Array.isArray(alertLogs) && alertLogs.length === 0}
                  onClick={() =>
                    setConfirmation({
                      show: true,
                      title: 'Acknowledgement',
                      message: 'Are you sure you want to acknowledge all notifications and clear the logs?',
                      confirmMessage: 'Yes, acknowledge all',
                      ackFunction: onAckAllClick(alertLogs),
                    })
                  }
                ></IconButton>
              </Card>
            </div>
            <ul className={s.list}>{renderAlertList(alertLogs)}</ul>
          </section>
        </Drawer>
      )}
      <ConfirmModal
        isOpen={confirmation?.show}
        title={confirmation?.title}
        body={confirmation?.message}
        confirmText={confirmation?.confirmMessage}
        onConfirm={() => {
          confirmation?.ackFunction();
          setConfirmation({
            ...confirmation,
            show: false,
          });
        }}
        onDismiss={() => setConfirmation({ ...confirmation, show: false })}
      />
    </>
  );
};

const getStyles = (theme: GrafanaTheme2) => {
  return {
    drawerStylesOverride: {
      drawerContent: css`
        background-color: ${theme.colors.background.sideMenu};
        color: ${theme.colors.text.navBar};
      `,
      header: css`
        background-color: ${theme.colors.background.sideMenu};
        color: ${theme.colors.text.navBar};
      `,
      actionButton: css`
        color: ${theme.colors.text.navBar};
        &:hover {
          color: ${theme.colors.text.navBar};
        }
      `,
      actions: css`
        color: ${theme.colors.text.navBar};
      `,
    },
    section: css`
      padding: 5px;
    `,
    subtitleSection: css`
      display: grid;
      grid-template-columns: 1fr 10px;
      justify-content: center;
      align-items: center;
    `,
    cardUnread: css`
      padding-top: 0;
      padding-bottom: 0;
      background: transparent;
      color: ${theme.colors.text.navBar};
    `,
    card: css`
      background-color: ${theme.colors.background.navBarHover};
      color: ${theme.colors.text.navBar};
      border-radius: 8px;
    `,
    cardOvertaken: css`
      opacity: 0.6;
    `,
    unreadIndicator: css`
      margin: 10px 0;
      color: ${theme.colors.text.navBar};
      opacity: 0.5;
    `,
    list: css`
      list-style: none;
    `,
    listItem: css`
      display: grid;
      grid-template-columns: 1fr 10px;
      justify-content: center;
      align-items: center;
    `,
    listItemChild: css`
      align-self: center;
      color: ${theme.colors.text.navBar};
      opacity: 0.8;

      small {
        span {
          display: inline;
          color: ${theme.colors.text.navBar};
          opacity: 0.8;
        }
      }
    `,
    tagName: css`
      font-weight: bold;
      color: ${theme.colors.text.navBar};
      opacity: 0.8;
    `,
    description: css`
      color: ${theme.colors.text.navBar};
      opacity: 0.8;
    `,
    actionButtons: css`
      display: flex;
      gap: 0.5em;
    `,
    actionButton: css`
      justify-self: center;
      align-self: center;
      color: ${theme.colors.text.navBar};
      border: 1px solid ${theme.colors.text.navBar};
      border-radius: 50px;
      padding: 0.5em;
      opacity: 0.8;
      &:hover {
        color: ${theme.colors.text.navBar};
      }
      &:disabled {
        color: ${theme.colors.text.navBar}88;
      }
    `,
    ackAllButton: css`
      border: none;
      padding: 0;
    `,
  };
};
