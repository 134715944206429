import { NavModelItem } from '@grafana/data';
import { getBackendSrv } from '@grafana/runtime';
import { populateCustomNavs } from 'app/core/reducers/navBarTree';
import { useDispatch, useSelector, StoreState } from 'app/types';

export const useDashboardStructure = (): [NavModelItem[], () => Promise<void>] => {
  const state = useSelector((state: StoreState) => state.navBarTree);
  const dispatch = useDispatch();

  const update = async () => {
    try {
      // total how many items including folders, nested folder and dashboards
      let results = await getBackendSrv().get('/api/search');

      // originally results only has dashboard hierarchy, but not nested folder,
      // so need to find all folders and nested folders, and then include these
      // information into the results
      // folders and nested folders
      let folders = await getBackendSrv().get('/api/folders');
      let folderParent: any = {};
      if (Array.isArray(folders) && folders.length > 0) {
        for (let folder of folders) {
          folderParent = await getFolderParent(folder, folderParent);
        }
      }

      for (let result of results) {
        if (result.type === 'dash-folder') {
          if (folderParent[result.uid]) {
            result.folderUid = folderParent[result.uid]?.uid;
            result.folderId = folderParent[result.uid]?.id;
            result.folderTitle = folderParent[result.uid]?.title;
            result.folderUrl = `/dashboards/f/${folderParent[result.uid]?.uid}/${folderParent[result.uid]?.title}`;
          }
        }
      }

      dispatch(populateCustomNavs(results));
    } catch (error) {
      console.error(error);
    }
  };

  const getFolderParent = async (parent: any, folderParent: any): Promise<any> => {
    let children = await getBackendSrv().get(`/api/folders?parentUid=${parent.uid}`);
    if (Array.isArray(children) && children.length > 0) {
      for (let child of children) {
        folderParent = { ...folderParent, [child.uid]: parent };
        folderParent = await getFolderParent(child, folderParent);
      }
    }
    return folderParent;
  };

  return [state, update];
};
