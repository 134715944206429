import { css, cx } from '@emotion/css';
import React from 'react';
import { useLocalStorage } from 'react-use';

import { GrafanaTheme2, NavModelItem } from '@grafana/data';
import { darken } from '@grafana/data/src/themes/colorManipulator';
import { Button, Icon, useStyles2, Text } from '@grafana/ui';
import { useGrafana } from 'app/core/context/GrafanaContext';

import { Indent } from '../../Indent/Indent';
import { NavBarItemIcon } from '../MegaMenu/NavBarItemIcon';

import { FeatureHighlight } from './FeatureHighlight';
import { MegaMenuItemText } from './MegaMenuItemText';
import { hasChildMatch } from './utils';

interface Props {
  link: NavModelItem;
  activeItem?: NavModelItem;
  onClick?: () => void;
  level?: number;
  isPersistentIcon?: boolean;
  onClose?: () => void;
}

// max level depth to render
export const MAX_DEPTH = 3; // NOTE: chewcw - come on, please, just don't create so many nested folders

export function MegaMenuItem({ link, activeItem, level = 0, onClick, isPersistentIcon, onClose }: Props) {
  const styles = useStyles2(getStyles, level);
  const FeatureHighlightWrapper = link.highlightText ? FeatureHighlight : React.Fragment;
  const hasActiveChild = hasChildMatch(link, activeItem);
  const [sectionExpanded, setSectionExpanded] =
    useLocalStorage(`grafana.navigation.expanded[${link.text}]`, false) ?? Boolean(hasActiveChild);
  const showExpandButton = level < MAX_DEPTH && (linkHasChildren(link) || link.emptyMessage);
  const { chrome } = useGrafana();
  const state = chrome.useState();

  const isActive = () => {
    if (isPersistentIcon) {
      if (activeItem) {
        if (link.children) {
          for (const children of link.children) {
            if (children.url === activeItem.url) {
              return true;
            }
          }
        }
        return link.url === activeItem.url;
      }
    } else {
      return link === activeItem;
    }
    return link === activeItem;
  };

  const renderIconElement = () => {
    if (link.icon || link.img) {
      return <NavBarItemIcon link={link} />
    }

    // profile
    if (link.roundIcon && link.img) {
      return <img className={styles.profileImgIcon} src={link.img} alt=""></img>;
    }

    link.icon = "graph-bar";
    return <NavBarItemIcon link={link} />;
  };

  const renderNested = (link: NavModelItem) => {
    return (
      <MegaMenuItem
        key={`${Math.random()}-${link.text}`}
        link={link}
        onClick={state.megaMenu === 'open' ? onClose : undefined}
        activeItem={activeItem}
        level={level + 1}
      />
    );
  };

  return (
    <li className={styles.listItem}>
      <div className={styles.collapsibleSectionWrapper}>
        <MegaMenuItemText
          isActive={isActive()}
          onClick={() => {
            link.onClick?.();
            onClick?.();
          }}
          target={link.target}
          url={link.url}
        >
          <div
            className={cx(styles.labelWrapper, {
              [styles.isActive]: isActive(),
            })}
          >
            <FeatureHighlightWrapper>
              <div className={styles.iconWrapper}>{renderIconElement()}</div>
            </FeatureHighlightWrapper>
            <Indent level={Math.max(0, level - 1)} spacing={2} />
            <Text truncate>{link.text}</Text>
          </div>
        </MegaMenuItemText>
        {!isPersistentIcon && showExpandButton && (
          <Button
            aria-label={`${sectionExpanded ? 'Collapse' : 'Expand'} section ${link.text}`}
            variant="secondary"
            fill="text"
            className={styles.collapseButton}
            onClick={() => setSectionExpanded(!sectionExpanded)}
          >
            <Icon name={sectionExpanded ? 'angle-up' : 'angle-down'} size="xl" />
          </Button>
        )}
      </div>
      {!isPersistentIcon && showExpandButton && sectionExpanded && (
        <ul className={styles.children}>
          {linkHasChildren(link) ? (
            link.children
              .filter((childLink) => !childLink.isCreateAction)
              .map((childLink) => {
                if (linkHasChildren(childLink)) {
                  return renderNested(childLink);
                }
                return (
                  <MegaMenuItem
                    key={`${link.text}-${childLink.text}`}
                    link={childLink}
                    activeItem={activeItem}
                    onClick={onClick}
                    level={level + 1}
                  />
                );
              })
          ) : (
            <div className={styles.emptyMessage}>{link.emptyMessage}</div>
          )}
        </ul>
      )}
    </li>
  );
}

const getStyles = (theme: GrafanaTheme2, level = 0) => ({
  children: css({
    display: 'flex',
    listStyleType: 'none',
    flexDirection: 'column',
    backgroundColor: darken(theme.colors.background.navBar, ++level / 10),
  }),
  collapsibleSectionWrapper: css({
    alignItems: 'center',
    display: 'flex',
  }),
  collapseButton: css({
    color: theme.colors.text.navBar,
    padding: theme.spacing(0, 0.5),
    marginRight: theme.spacing(1),
    '&:hover, &:focus-visible': {
      background: theme.colors.emphasize(theme.colors.background.navBarHover, 0.03),
      color: theme.colors.text.navBarHover,
    },
  }),
  emptyMessage: css({
    color: theme.colors.text.navBar,
    fontStyle: 'italic',
    padding: theme.spacing(1, 1.5, 1, 7),
  }),
  iconWrapper: css({
    display: 'inline-flex',
    alignItems: 'center',
    justifyContent: 'center',
  }),
  labelWrapper: css({
    display: 'grid',
    fontSize: theme.typography.pxToRem(14),
    gridAutoFlow: 'column',
    gridTemplateColumns: `${theme.spacing(7)} auto`,
    alignItems: 'center',
    fontWeight: theme.typography.fontWeightMedium,
  }),
  listItem: css({
    flex: 1,
  }),
  isActive: css({
    color: theme.colors.text.navBarActive,

    '&::before': {
      display: 'block',
      content: '" "',
      height: theme.spacing(3),
      position: 'absolute',
      left: theme.spacing(1),
      top: '50%',
      transform: 'translateY(-50%)',
      width: theme.spacing(0.5),
      borderRadius: theme.shape.radius.default,
      backgroundImage: theme.colors.gradients.brandVertical,
    },
  }),
  imgIcon: css({
    width: '24px',
    height: '24px',
    objectFit: 'cover',
  }),
  profileImgIcon: css({
    width: '24px',
    height: '24px',
    objectFit: 'cover',
    // eslint-disable-next-line @grafana/no-border-radius-literal
    borderRadius: '10px',
  }),
});

export function linkHasChildren(link: NavModelItem): link is NavModelItem & { children: NavModelItem[] } {
  return Boolean(link.children && link.children.length > 0);
}
