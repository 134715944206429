import { css } from '@emotion/css';
import { cloneDeep } from 'lodash';
import React, { useEffect } from 'react';
import { useLocation } from 'react-router-dom';

import { GrafanaTheme2 } from '@grafana/data';
import { useTheme2 } from '@grafana/ui';

import { renderNavItems } from '../navItemUtils';
import { useDashboardStructure } from '../useDashboardStructure';

import { NavBarMenu } from './NavBarMenuPersistentIcon';
import { enrichWithInteractionTracking, getActiveItem } from './utils';

export interface Props {
  onClose: () => void;
  searchBarHidden?: boolean;
}

export const MegaMenu = React.memo<Props>(({ onClose, searchBarHidden }) => {
  const [navBarTree, updateDashboardStructure] = useDashboardStructure();
  const theme = useTheme2();
  const styles = getStyles(theme);
  const location = useLocation();

  const navTree = cloneDeep(navBarTree);

  // eslint-disable-next-line react-hooks/rules-of-hooks
  useEffect(() => {
    updateDashboardStructure();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const navItems = renderNavItems(navTree, enrichWithInteractionTracking);

  const activeItem = getActiveItem(navItems, location.pathname);

  return (
    <div className={styles.menuWrapper}>
      <NavBarMenu activeItem={activeItem} navItems={navItems} onClose={onClose} searchBarHidden={searchBarHidden} />
    </div>
  );
});

MegaMenu.displayName = 'MegaMenu';

const getStyles = (theme: GrafanaTheme2) => ({
  menuWrapper: css({
    position: 'fixed',
    display: 'grid',
    gridAutoFlow: 'column',
    height: '100%',
    zIndex: theme.zIndex.sidemenu,
  }),
});
