import React from 'react';

import { useTheme2 } from '@grafana/ui';

export interface Props {
  screenWidth: number;
}

export function YnyFooter({ screenWidth }: Props) {
  const theme = useTheme2();

  const getFooterFontSize = () => {
    if (screenWidth <= 320) {
      return '6px';
    } else if (screenWidth < 420) {
      return '8px';
    } else {
      return '10px';
    }
  };

  return (
    <div
      style={{
        display: window.location.href.includes('login') ? 'none' : 'flex',
        position: 'fixed',
        justifyContent: 'flex-end',
        bottom: '0px',
        height: '15px',
        width: '100%',
        backgroundColor: theme.colors.background.footer,
        color: theme.colors.text.navBar,
        zIndex: theme.zIndex.footer,
        fontSize: getFooterFontSize(),
      }}
    >
      <div
        style={{
          display: 'flex',
          alignItems: 'center',
        }}
      >
        <div
          style={{
            marginRight: '12px',
          }}
        >
          <a
            style={{ color: theme.colors.text.navBar }}
            href={'https://www.ynytechnology.com.my/privacy-policy/'}
            target={'_blank'}
            rel={'noreferrer'}
          >
            Privacy Policy
          </a>
          <span style={{ margin: '0px 8px', color: theme.colors.text.navBar }}>|</span> © 2023 &nbsp;
          <a
            style={{ color: theme.colors.text.navBar }}
            target={'_blank'}
            href={'https://www.ynytechnology.com.my'}
            rel={'noreferrer'}
          >
            YNY TECHNOLOGY SDN BHD
          </a>
        </div>
        <img src={'public/img/yny_arrow.png'} style={{ height: '100%' }} alt={'yny_arrow'} />
      </div>
    </div>
  );
}
