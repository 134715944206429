export enum NotfType {
    unspecified = 'unspecified',
    telegram = 'telegram',
    email = 'sendgrid_email',
    mssql_sp = 'mssql_sp',
    whatsapp = 'infobip_whatsapp',
}

export enum NotfThresholdType {
    unspecified = 'unspecified',
    greater_than = 'greater_than',
    greater_equal_than = 'greater_equal_than',
    lower_than = 'lower_than',
    lower_equal_than = 'lower_equal_than',
}

export enum NotfTriggerType {
    unspecified = 'unspecified',
    on_received = 'on_received',
    on_changed = 'on_changed',
}

export enum NotfSeverityType {
    Low = "Low",
    High = "High",
}
