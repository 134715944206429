import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { NavModelItem } from '@grafana/data';
import { config } from '@grafana/runtime';
import { DashboardSearchItem, DashboardSearchItemType } from 'app/features/search/types';

import { getNavSubTitle, getNavTitle } from '../utils/navBarItem-translations';

export const initialState: NavModelItem[] = config.bootData?.navTree ?? [];

function translateNav(navTree: NavModelItem[]): NavModelItem[] {
  return navTree.map((navItem) => {
    const children = navItem.children && translateNav(navItem.children);

    return {
      ...navItem,
      children: children,
      text: getNavTitle(navItem.id) ?? navItem.text,
      subTitle: getNavSubTitle(navItem.id) ?? navItem.subTitle,
      emptyMessage: getNavTitle(navItem.emptyMessageId),
    };
  });
}

// this matches the prefix set in the backend navtree
export const ID_PREFIX = 'starred/';

const navTreeSlice = createSlice({
  name: 'navBarTree',
  initialState: () => translateNav(config.bootData?.navTree ?? []),
  reducers: {
    setStarred: (state, action: PayloadAction<{ id: string; title: string; url: string; isStarred: boolean }>) => {
      const starredItems = state.find((navItem) => navItem.id === 'starred');
      const { id, title, url, isStarred } = action.payload;
      if (starredItems) {
        if (isStarred) {
          if (!starredItems.children) {
            starredItems.children = [];
          }
          const newStarredItem: NavModelItem = {
            id: ID_PREFIX + id,
            text: title,
            url,
          };
          starredItems.children.push(newStarredItem);
          starredItems.children.sort((a, b) => a.text.localeCompare(b.text));
        } else {
          const index = starredItems.children?.findIndex((item) => item.id === ID_PREFIX + id) ?? -1;
          if (index > -1) {
            starredItems?.children?.splice(index, 1);
          }
        }
      }
    },
    updateDashboardName: (state, action: PayloadAction<{ id: string; title: string; url: string }>) => {
      const { id, title, url } = action.payload;
      const starredItems = state.find((navItem) => navItem.id === 'starred');
      if (starredItems) {
        const navItem = starredItems.children?.find((navItem) => navItem.id === id);
        if (navItem) {
          navItem.text = title;
          navItem.url = url;
          starredItems.children?.sort((a, b) => a.text.localeCompare(b.text));
        }
      }
    },
    removePluginFromNavTree: (state, action: PayloadAction<{ pluginID: string }>) => {
      const navID = 'plugin-page-' + action.payload.pluginID;
      const pluginItemIndex = state.findIndex((navItem) => navItem.id === navID);
      if (pluginItemIndex > -1) {
        state.splice(pluginItemIndex, 1);
      }
    },
    populateCustomNavs: (state, action: PayloadAction<DashboardSearchItem[]>) => {
      let [customNavs] = assignNavItems([], action.payload);
      state.length = 0;
      state.push(...initialState, ...customNavs);
    },
  },
});

function assignNavItems(customNavs: any[], results: Array<DashboardSearchItem | null>): any[] {
  let tmpResults = [...results];
  let tmpCustomNavs = [...customNavs];
  for (let i = 0; i < tmpResults.length; ++i) {
    if (!tmpResults[i]?.folderUid) {
      if (tmpResults[i]?.hidden) {
        tmpResults.splice(i, 1, null);
        continue;
      }

      tmpCustomNavs.push({
        uid: tmpResults[i]?.uid,
        id: tmpResults[i]?.uri,
        icon: tmpResults[i]?.icon || 'folder',
        isSection: tmpResults[i]?.type === DashboardSearchItemType.DashFolder ? true : false,
        sortWeight: -1000,
        subTitle: tmpResults[i]?.title,
        text: tmpResults[i]?.title,
        url: tmpResults[i]?.url,
        children: [],
      });
      tmpResults.splice(i, 1, null);
    }
  }

  return assignNavChildItems(tmpCustomNavs, tmpResults);
}

function assignNavChildItems(customNavs: any[], results: Array<DashboardSearchItem | null>): any[] {
  let tmpResults = [...results];
  let tmpCustomNavs = [...customNavs];

  if (tmpResults.filter((tmpResult) => !tmpResult).length === 0) {
    return [tmpCustomNavs, tmpResults];
  }

  for (let i = 0; i < tmpResults.length; ++i) {
    let assigned = false;

    if (!tmpResults[i]) {
      continue;
    }

    for (let tmpCustomNav of tmpCustomNavs) {
      if (tmpCustomNav.uid === tmpResults[i]?.folderUid) {
        if (tmpResults[i]?.type === DashboardSearchItemType.DashFolder) {
          tmpCustomNav.children.push({
            uid: tmpResults[i]?.uid,
            id: tmpResults[i]?.uri,
            icon: tmpResults[i]?.icon || 'folder',
            isSection: true,
            sortWeight: -1000,
            subTitle: tmpResults[i]?.title,
            text: tmpResults[i]?.title,
            url: tmpResults[i]?.url,
            children: [],
          });
        }
        if (tmpResults[i]?.type === DashboardSearchItemType.DashDB) {
          tmpCustomNav.children.push({
            uid: tmpResults[i]?.uid,
            id: `db/${tmpResults[i]?.url.split('/')[3]}`,
            icon: tmpResults[i]?.icon || 'graph-bar',
            text: tmpResults[i]?.title,
            url: tmpResults[i]?.url,
            children: [],
          });
        }
        tmpResults.splice(i, 1, null);
        assigned = true;
        break;
      }
    }

    if (!assigned) {
      for (let tmpCustomNav of tmpCustomNavs) {
        [tmpCustomNav.children, tmpResults] = assignNavChildItems(tmpCustomNav.children, tmpResults);
      }
    }
  }

  return [tmpCustomNavs, tmpResults];
}

export const { setStarred, removePluginFromNavTree, updateDashboardName, populateCustomNavs } = navTreeSlice.actions;
export const navTreeReducer = navTreeSlice.reducer;
