// Libraries
import { css, cx } from '@emotion/css';
import React, { useLayoutEffect } from 'react';

import { GrafanaTheme2, PageLayoutType } from '@grafana/data';
import { config } from '@grafana/runtime';
import { CustomScrollbar, useStyles2 } from '@grafana/ui';
import { useGrafana } from 'app/core/context/GrafanaContext';
import { KioskMode } from 'app/types';

import { MENU_WIDTH as DOCKED_MENU_WIDTH } from '../AppChrome/DockedMegaMenu/MegaMenu';
import { MENU_WIDTH as ICON_PERSISTENT_MENU_WIDTH } from '../AppChrome/DockedMegaMenu/MegaMenuPersistentIcon';
import { TOP_BAR_LEVEL_HEIGHT } from '../AppChrome/types';

import { PageContents } from './PageContents';
import { PageHeader } from './PageHeader';
import { PageTabs } from './PageTabs';
import { PageType } from './types';
import { usePageNav } from './usePageNav';
import { usePageTitle } from './usePageTitle';

export const Page: PageType = ({
  navId,
  navModel: oldNavProp,
  pageNav,
  renderTitle,
  onEditTitle,
  onEditIcon,
  onEditHidden,
  actions,
  subTitle,
  children,
  className,
  info,
  layout = PageLayoutType.Standard,
  scrollTop,
  scrollRef,
  ...otherProps
}) => {
  const navModel = usePageNav(navId, oldNavProp);
  const { chrome } = useGrafana();
  const state = chrome.useState();
  const searchBarHidden = state.searchBarHidden || state.kioskMode === KioskMode.TV;
  let dockedSidenavWidth = ICON_PERSISTENT_MENU_WIDTH;
  if (state.megaMenu === 'closed') {
    dockedSidenavWidth = '0px';
  }
  if (state.megaMenu === 'docked') {
    dockedSidenavWidth = DOCKED_MENU_WIDTH;
  }
  const styles = useStyles2(getStyles(dockedSidenavWidth));

  usePageTitle(navModel, pageNav);

  const pageHeaderNav = pageNav ?? navModel?.node;

  // We use useLayoutEffect here to make sure that the chrome is updated before the page is rendered
  // This prevents flickering sectionNav when going from dashboard to settings for example
  useLayoutEffect(() => {
    if (navModel) {
      chrome.update({
        sectionNav: navModel,
        pageNav: pageNav,
        layout: layout,
      });
    }
  }, [navModel, pageNav, chrome, layout]);

  const maskMinHeight = searchBarHidden ? TOP_BAR_LEVEL_HEIGHT + 10 : TOP_BAR_LEVEL_HEIGHT * 2 + 10;

  return (
    <div className={cx(styles.wrapper, className)} {...otherProps}>
      {layout === PageLayoutType.Standard && (
        <CustomScrollbar autoHeightMin={'100%'} scrollTop={scrollTop} scrollRefCallback={scrollRef}>
          <div style={{ minHeight: maskMinHeight, width: '100%', display: 'block' }}></div>
          <div className={styles.pageInner}>
            {pageHeaderNav && (
              <PageHeader
                actions={actions}
                onEditTitle={onEditTitle}
                onEditIcon={onEditIcon}
                onEditHidden={onEditHidden}
                navItem={pageHeaderNav}
                renderTitle={renderTitle}
                info={info}
                subTitle={subTitle}
              />
            )}
            {pageNav && pageNav.children && <PageTabs navItem={pageNav} />}
            <div className={styles.pageContent}>{children}</div>
          </div>
        </CustomScrollbar>
      )}
      {layout === PageLayoutType.Canvas && (
        <CustomScrollbar autoHeightMin={'100%'} scrollTop={scrollTop} scrollRefCallback={scrollRef}>
          <div style={{ minHeight: maskMinHeight, width: '100%', display: 'block' }}></div>
          <div className={styles.canvasContent}>{children}</div>
        </CustomScrollbar>
      )}
      {layout === PageLayoutType.Custom && (
        <>
          <div style={{ minHeight: maskMinHeight, width: '100%', display: 'block' }}></div>
          {children}
        </>
      )}
    </div>
  );
};

Page.Contents = PageContents;

const getStyles = (dockedMenuWidth: string) => {
  return (theme: GrafanaTheme2) => {
    return {
      wrapper: css({
        label: 'page-wrapper',
        height: '100%',
        display: 'flex',
        flex: '1 1 0',
        flexDirection: 'column',
        minHeight: 0,
        overflow: 'hidden',
        top: 0,
        position: 'fixed',
        width: '100%',
      }),
      pageContent: css({
        label: 'page-content',
        flexGrow: 1,
        width: `calc(100% - ${dockedMenuWidth})`,
      }),
      pageInner: css({
        label: 'page-inner',
        padding: theme.spacing(2),
        borderRadius: theme.shape.radius.default,
        border: `1px solid ${theme.colors.border.weak}`,
        borderBottom: 'none',
        background: theme.colors.background.primary,
        display: 'flex',
        flexDirection: 'column',
        flexGrow: 1,
        margin: theme.spacing(0, 0, 0, 0),
        width: `calc(100% - ${dockedMenuWidth})`,

        [theme.breakpoints.up('md')]: {
          margin: theme.spacing(2, 2, 0, config.featureToggles.dockedMegaMenu ? 2 : 1),
          padding: theme.spacing(3),
        },
      }),
      canvasContent: css({
        label: 'canvas-content',
        display: 'flex',
        flexDirection: 'column',
        padding: theme.spacing(2),
        flexBasis: '100%',
        flexGrow: 1,
        width: `calc(100% - ${dockedMenuWidth})`,
      }),
    };
  };
};
