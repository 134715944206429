import { NavModelItem } from '@grafana/data';

// Remove profile + help + alerting + explore + connections + starred from tree
export const renderNavItems = (
  navTree: NavModelItem[],
  enrichWithInteractionTracking: (item: NavModelItem, expandedState: boolean) => NavModelItem,
): NavModelItem[] => {
  let navItems = navTree.map((item) => enrichWithInteractionTracking(item, true));
  return sortNavItems(navItems);
};

// Sort the navItems, home -> custom dashboards -> dashboard browser -> others
const sortNavItems = (navTree: NavModelItem[]): NavModelItem[] => {
  const homeIndex = navTree.findIndex((item) => item?.id === 'home');
  // const dashboardBrowserIndex = navTree.findIndex((item) => item?.id?.includes('dashboards/browse'));
  const customDashboardsIndex = navTree.findIndex((item) => item?.id?.startsWith('db/'));
  const customDashboardsCount = navTree.filter((item) => item?.id?.startsWith('db/')).length;
  const splicedCustomDashboards = navTree.splice(customDashboardsIndex, customDashboardsCount);

  navTree.splice(homeIndex + 1, 0, ...splicedCustomDashboards);

  return navTree;
};
