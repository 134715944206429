import { css, cx } from '@emotion/css';
import React from 'react';

import { NavModelItem, GrafanaTheme2 } from '@grafana/data';
import { Field, Icon, Switch, useStyles2 } from '@grafana/ui';
import { t } from 'app/core/internationalization';

import { PageInfo } from '../PageInfo/PageInfo';

import { EditableIcon } from './EditableIcon';
import { EditableTitle } from './EditableTitle';
import { PageInfoItem } from './types';

export interface Props {
  navItem: NavModelItem;
  renderTitle?: (title: string) => React.ReactNode;
  actions?: React.ReactNode;
  info?: PageInfoItem[];
  subTitle?: React.ReactNode;
  onEditTitle?: (newValue: string) => Promise<void>;
  onEditIcon?: (newValue: string) => Promise<void>;
  onEditHidden?: (newValue: boolean) => Promise<void>;
}

export function PageHeader({
  navItem,
  renderTitle,
  actions,
  info,
  subTitle,
  onEditTitle,
  onEditIcon,
  onEditHidden,
}: Props) {
  const styles = useStyles2(getStyles);
  const sub = subTitle ?? navItem.subTitle;

  const titleElement = onEditTitle ? (
    <EditableTitle value={navItem.text} onEdit={onEditTitle} />
  ) : (
    <div className={styles.title}>
      {navItem.img && <img className={styles.img} src={navItem.img} alt={`logo for ${navItem.text}`} />}
      {renderTitle ? renderTitle(navItem.text) : <h1>{navItem.text}</h1>}
    </div>
  );

  const renderIconElement = () => {
    if (onEditIcon) {
      return <EditableIcon value={navItem.icon || 'folder'} onEdit={onEditIcon} />;
    }
    if (navItem.icon?.startsWith('<svg')) {
      return (
        <img
          className={cx(styles.iconImg)}
          src={`data:image/svg+xml;utf8,${encodeURIComponent(navItem.icon)}`}
          alt="icon"
        />
      );
    }
    return (
      <div className={styles.icon}>
        <Icon name={navItem.icon || 'folder'} />
      </div>
    );
  };

  const renderHiddenOption = () => {
    if (onEditHidden) {
      return (
        <Field
          label={t('dashboard-settings.general.show-in-sidenav', 'Show in sidenav')}
          description={t('dashboard-settings.general.show-in-sidenav', 'Show this folder in the side navigation')}
        >
          <Switch id="show-in-sidenav" value={!navItem.hidden} onChange={(e: any) => onEditHidden(e.target.checked)} />
        </Field>
      );
    }
    return null;
  };

  return (
    <div className={styles.pageHeader}>
      <div className={styles.topRow}>
        <div className={styles.titleInfoContainer}>
          {renderIconElement()}
          {titleElement}
          {info && <PageInfo info={info} />}
        </div>
        <div className={styles.actions}>{actions}</div>
      </div>
      {sub && <div className={styles.subTitle}>{sub}</div>}
      <div className={styles.secondRow}>
        {renderHiddenOption()}
      </div>
    </div>
  );
}

const getStyles = (theme: GrafanaTheme2) => {
  return {
    topRow: css({
      alignItems: 'flex-start',
      display: 'flex',
      flexDirection: 'row',
      flexWrap: 'wrap',
      gap: theme.spacing(1, 3),
    }),
    secondRow: css({
      alignItems: 'center',
      display: 'flex',
      flexDirection: 'row',
      flexWrap: 'wrap',
      borderTop: `1px solid ${theme.colors.border.weak}`,
      paddingTop: theme.spacing.x1,
    }),
    title: css({
      display: 'flex',
      flexDirection: 'row',
      h1: {
        display: 'flex',
        marginBottom: 0,
      },
    }),
    actions: css({
      display: 'flex',
      flexDirection: 'row',
      gap: theme.spacing(1),
    }),
    titleInfoContainer: css({
      display: 'flex',
      label: 'title-info-container',
      flex: 1,
      flexWrap: 'wrap',
      gap: theme.spacing(1, 2),
      maxWidth: '100%',
      minWidth: '200px',
    }),
    pageHeader: css({
      label: 'page-header',
      display: 'flex',
      flexDirection: 'column',
      gap: theme.spacing(1),
      marginBottom: theme.spacing(2),
    }),
    subTitle: css({
      position: 'relative',
      color: theme.colors.text.secondary,
    }),
    img: css({
      width: '32px',
      height: '32px',
      marginRight: theme.spacing(2),
    }),
    iconImg: css({
      width: '24px',
      height: '24px',
      objectFit: 'cover',
    }),
    icon: css({
      display: 'flex',
      alignItems: 'center',
    }),
  };
};
